<template>
  <v-footer id="de-footer" class="align-end" app color="primary" dark padless>

      <v-row  class="primary lighten-5 mt-4 justify-space-around">

        <v-col
          cols="3"
        >
          <v-card outlined class="transparent text-left px-2">

          </v-card>
        </v-col>

        <v-col
          cols="3"
          class="text-center px-2 primary lighten-5 d-flex flex-column justify-center align-center"
        >
            <v-img
              src="@/assets/de-imgs/engineering.png"
              contain
              :aspect-ratio="4.5"
              :width="174"
            ></v-img>
            <div class="caption">{{ new Date().getFullYear() }}</div>
        </v-col>

        <v-col
          cols="3"
          class="px-2 primary lighten-5 d-flex align-end flex-column justify-center"
          padless
        >
            <div class="caption">{{$t("message.labels.powered-by")}}</div>
            <v-img
              src="@/assets/de-imgs/fiware-logo.png"
              contain
              :aspect-ratio="4.5"
              :width="104"
            ></v-img>
        </v-col>

      </v-row>

  </v-footer>
</template>


<script> 
  export default {
    name: 'CoreFooter'
  }
</script>

<style scoped>
  #de-footer{
    height: 90px;
  }
  .v-footer--fixed {
    position: absolute;
    z-index: 4;
  }
</style>